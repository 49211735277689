<template>
  <v-container fluid>
    {{ textoRespuesta }}
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'Upload',
    data: () => ({
      reglaUpload: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          'El archivo no debe pesar más de 5 MB',
      ],
      textoRespuesta: '',
      usuario: '',
      nombreUsuario: '',
      mesUpdate: '',
      sedeCorteData: '',
      carga: false,
      archivoCsv: [],
      archivoCsv2: [],
      archivoCsv3: [],
      dataUpdate: [],
      urlDataSede: '',
      sumaI: 0,
      incorrectos: 0,
      urlLogo: '',
      mesConsulta: '',
      arrayFinal: [],
      itemsXls: [],
      dataConsulta: [],
      dataUsuario: [],
      dataEnviar: [],
    }),
    created () {
      this.textoRespuesta = '.-=LOADING=-.'
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioDataCSJLA'))
      this.getProduccionSij()
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
      /* leerDataSede () {
        axios
          .get(
            this.urlDataSede,
          )
          .then((response) => {
            this.dataSede = response.data.array.filter(item => item.id === this.sedeCorteData)
            this.urlLogo = 'assets/' + this.dataSede[0].logo
            if (this.urlLogo === 'assets/') { this.urlLogo = 'assets/pj-logo.svg' }
            // this.dataSede = Object.values(response.data).filter(item => item.id === this.sedeCorteData)
            // console.log(Object.values(response.data))
          })
          .catch((e) => {
          console.log(e); // eslint-disable-line
          })
      }, */
      getProduccionSij () {
        axios
          .get('http://172.17.133.188:3005/produccion/produccionAnual')
          // .get(`${window.__env.dataCfg.urlApiSIJWS}produccion/produccionAnual`)
          .then((r) => {
            // var arrayAgrupado = []
            var arreglo = r.data.data
            const lodash = require('lodash')
            // const groupByInsta = lodash.groupBy(arreglo, prod => prod.n_dependencia)
            // console.log(groupByInsta)
            // var finalArray = []

            // var dataMes = []

            const groupByTodo = lodash.groupBy(arreglo, prod => `"${prod.n_dependencia}+${prod.MES}+${prod.var_des}"`)
            const groupByTodo2 = lodash.groupBy(arreglo, prod => `"${prod.n_dependencia}+${prod.MES}"`)
            lodash.forEach(groupByTodo2, (value, key) => {
              // const juzgadoEnviar = { ano: value[0].ANO, nombre: value[0].x_nom_instancia, apela_confirmada: arrayInside.CONFIRMADA ? arrayInside.CONFIRMADA.length : 0, apela_revocada: arrayInside.REVOCADA ? arrayInside.REVOCADA.length : 0, apela_anulada: arrayInside.ANULADA ? arrayInside.ANULADA.length : 0, auto: parseInt(arrayInside['AUTO DE SOBRESEIMIENTO'] ? arrayInside['AUTO DE SOBRESEIMIENTO'].length : 0) + parseInt(arrayInside['OTROS AUTOS FINALES'] ? arrayInside['OTROS AUTOS FINALES'].length : 0) + parseInt(arrayInside['AUTO DE ENJUICIAMIENTO'] ? arrayInside['AUTO DE ENJUICIAMIENTO'].length : 0), conciliacion: arrayInside.CONCILIADOS ? arrayInside.CONCILIADOS.length : 0, demanda_improcedente: arrayInside['DEMANDA IMPROCEDENTE/ DENUNCIA NO HA LUGAR RESUELTA'] ? arrayInside['DEMANDA IMPROCEDENTE/ DENUNCIA NO HA LUGAR RESUELTA'].length : 0, id: element.n_dependencia, mes: element.MES, sentencia: arrayInside.SENTENCIAS ? arrayInside.SENTENCIAS.length : 0 }
              // console.log(juzgadoEnviar)
              var values = key.slice(1, -1).split('+')
              var busqueda = values[0] + '-' + values[1]
              var juzgadoData = []
              lodash.forEach(groupByTodo, (value, key) => {
                var values2 = key.slice(1, -1).split('+')
                var busqueda2 = values2[0] + '-' + values2[1]
                if (busqueda === busqueda2) {
                  juzgadoData[value[0].var_des] = Object.keys(value).length
                }
              })
              console.log(juzgadoData)
              const juzgadoEnviar = { ano: value[0].ANO, nombre: value[0].x_nom_instancia, apela_confirmada: juzgadoData.CONFIRMADA ? juzgadoData.CONFIRMADA : 0, apela_revocada: juzgadoData.REVOCADA ? juzgadoData.REVOCADA : 0, apela_anulada: juzgadoData.ANULADA ? juzgadoData.ANULADA : 0, auto: parseInt(juzgadoData['AUTO DE SOBRESEIMIENTO'] ? juzgadoData['AUTO DE SOBRESEIMIENTO'] : 0) + parseInt(juzgadoData['OTROS AUTOS FINALES'] ? juzgadoData['OTROS AUTOS FINALES'] : 0) + parseInt(juzgadoData['AUTO DE ENJUICIAMIENTO'] ? juzgadoData['AUTO DE ENJUICIAMIENTO'] : 0) + parseInt(juzgadoData['CUESTIÓN PREVIA'] ? juzgadoData['CUESTIÓN PREVIA'] : 0), conciliacion: juzgadoData.CONCILIADOS ? juzgadoData.CONCILIADOS : 0, demanda_improcedente: juzgadoData['DEMANDA IMPROCEDENTE/ DENUNCIA NO HA LUGAR RESUELTA'] ? juzgadoData['DEMANDA IMPROCEDENTE/ DENUNCIA NO HA LUGAR RESUELTA'] : 0, id: value[0].n_dependencia, mes: value[0].MES, sentencia: juzgadoData.SENTENCIAS ? juzgadoData.SENTENCIAS : 0 }
              console.log(juzgadoEnviar)
              this.addArray(juzgadoEnviar)
              // this.updateProduccion(juzgadoEnviar, Object.keys(groupByTodo2).length)
              /* for (var i = 1; i < 13; i++) {
                if (i === parseInt(values[1])) {
                }
              } */
              // const juzgadoEnviar = { ano: value[0].ANO, nombre: value[0].x_nom_instancia, apela_confirmada: arrayInside.CONFIRMADA ? arrayInside.CONFIRMADA.length : 0, apela_revocada: arrayInside.REVOCADA ? arrayInside.REVOCADA.length : 0, apela_anulada: arrayInside.ANULADA ? arrayInside.ANULADA.length : 0, auto: parseInt(arrayInside['AUTO DE SOBRESEIMIENTO'] ? arrayInside['AUTO DE SOBRESEIMIENTO'].length : 0) + parseInt(arrayInside['OTROS AUTOS FINALES'] ? arrayInside['OTROS AUTOS FINALES'].length : 0) + parseInt(arrayInside['AUTO DE ENJUICIAMIENTO'] ? arrayInside['AUTO DE ENJUICIAMIENTO'].length : 0), conciliacion: arrayInside.CONCILIADOS ? arrayInside.CONCILIADOS.length : 0, demanda_improcedente: arrayInside['DEMANDA IMPROCEDENTE/ DENUNCIA NO HA LUGAR RESUELTA'] ? arrayInside['DEMANDA IMPROCEDENTE/ DENUNCIA NO HA LUGAR RESUELTA'].length : 0, id: element.n_dependencia, mes: element.MES, sentencia: arrayInside.SENTENCIAS ? arrayInside.SENTENCIAS.length : 0 }
            })
            console.log(this.dataEnviar)
            this.updateProduccion()
            // this.dataConsulta = finalArray
          })
          .catch((err) => {
            this.carga = false
            alert('Servicio no disponible' + err)
          })
      },
      groupBy (keys) {
        return function (array) {
          return array.reduce(function (objectsByKeyValue, obj) {
            var value = keys.map(function (key) { return (obj[key]) }).join('-')
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
            return objectsByKeyValue
          }, {})
        }
      },
      validarZeroes (val) {
        if (val || val === 0) {
          return false
        }
        return true
      },
      addArray (item) {
        const data = {
          id: item.id,
          mes: item.mes,
          ano: item.ano,
          sede: 17,
          sentencia: item.sentencia,
          auto: item.auto,
          conciliacion: item.conciliacion,
          demanda_improcedente: item.demanda_improcedente,
          apela_confirmada: item.apela_confirmada,
          apela_revocada: item.apela_revocada,
          apela_anulada: item.apela_anulada,
          calidad_confirmada: 0,
          calidad_revocada: 0,
          calidad_anulada: 0,
          usuario: 2,
        }
        this.dataEnviar.push(data)
      },
      updateProduccion () {
        axios
          .post(`${window.__env.dataCfg.urlApiData}estadistica/actualizar_bloque/`, this.dataEnviar)
          .then((r) => {
            console.log(r)
            this.textoRespuesta = 'SE HA ACTIVADO LA BOMBA'
            this.dataEnviar = []
            r.data.resp === true ? alert('ACTUALIZADO, ' + r.data.nro_filas + ' FILAS') : alert('ERROR')
          })
          .catch((err) => {
            alert('Servicio no disponible' + err)
          })
      },
    },
  }
</script>
